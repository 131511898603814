.create-edit-removal .pallet-container {
  display: flex;
  justify-content: space-between;
}

.create-edit-removal .pallet-header {
  font-size: 16px;
  font-style: italic;
  font-weight: bold;
  text-align: center;
}

.create-edit-removal .label-print {
  cursor: pointer;
  font-size: 20px;
}

.create-edit-removal .label-print:hover {
  color: rgb(85, 84, 84);
}

.create-edit-removal .complete-lock-locked {
  color: #4285F4;
  cursor: pointer;
  font-size: 20px;
}

.create-edit-removal .complete-lock-locked:hover {
  color: #3569bd;
}

.create-edit-removal .complete-text {
  color: red;
  font-style: italic;
}

.create-edit-removal .section-header {
  font-size: 17px;
  font-style: italic;
  font-weight: bold;
}

.create-edit-removal .box {
  display: flex;
  justify-content: center;
  text-align: center;
}

.create-edit-removal .category-titles {
  font-size: 12px;
  font-weight: bold;
  padding-right: 20px;
}

.create-edit-removal .category-text {
  color: #00008b;
  min-width: 135px;
}

.create-edit-removal .image {
  cursor: pointer;
  height: auto;
  max-height: 180px;
  width: 70%;
}

.create-edit-removal .info-text {
  font-size: 13px;
  font-style: italic;
}

.create-edit-removal .placement-instructions {
  font-size: 15px;
  font-weight: bold;
  font-style: italic;
}

.create-edit-removal .not-found {
  color: blue;
  font-size: 14px;
  font-weight: bold;
  font-style: italic;
}

.create-edit-removal .not-found {
  color: blue;
  font-size: 14px;
  font-weight: bold;
  font-style: italic;
}

.create-edit-removal .trash-can {
  color: red;
  cursor: pointer;
  font-size: 18px;
}

.create-edit-removal .trash-can:hover {
  color: rgb(168, 4, 4);
}

.create-edit-removal .input-label {
  font-size: 14px;
}