.product-lookup-search {
  margin-right: 20px;
  margin-top: 4px;
}

.sidebar-toggle-icon {
  cursor: pointer;
  margin-bottom: 6px;
}

.sidebar-toggle-icon:hover {
  color: gray;
}

.background-nav {
  background-image: url('../../../public/sheldon-thompson-CXET9y0BmkE-unsplash2.jpg');
  background-color: #000000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}