.product-lookup .category-blue {
  color: #00008b;
}

.product-lookup .edit-icon {
  color: black;
  cursor: pointer;
  margin-left: 10px;
  margin-bottom: 5px;
}

.product-lookup .edit-icon:hover {
  color: rgb(105, 103, 103);
}

.product-lookup .pointer {
  cursor: pointer;
}
