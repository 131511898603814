.return-tracking-history .section-header {
  font-size: 17px;
  font-style: italic;
  font-weight: bold;
}

.return-tracking-history .category-titles {
  font-size: 12px;
  font-weight: bold;
  padding-right: 20px;
}

.return-tracking-history .category-text {
  color: #00008b;
  min-width: 135px;
}

.return-tracking-history .edit-icon {
  color: black;
  cursor: pointer;
  font-size: 15px;
  margin-left: 10px;
  margin-bottom: 5px;
}

.return-tracking-history .edit-icon:hover {
  color: rgb(105, 103, 103);
}

.return-tracking-history .complete-text {
  font-weight: bold;
  float: right;
  text-align: right;
  margin-top: 3px;
}

.return-tracking-history .reimburse-icon {
  color: #28a745;
  cursor: pointer;
  float: right;
  font-size: 30px;
  margin-left: 10px;
  text-align: right;
}

.return-tracking-history .reimburse-icon:hover {
  color: darkgreen;
}

.return-tracking-history .image-icon {
  color: #0dcaf0;
  cursor: pointer;
  float: right;
  font-size: 30px;
  margin-left: 10px;
  text-align: right;
}

.return-tracking-history .image-icon:hover {
  color: darkcyan;
}

.return-tracking-history .comms-text {
  font-size: 14px;
}

.return-tracking-history .comms-headers {
  font-weight: bold;
  text-decoration: underline;
}