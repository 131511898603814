.pro-sidebar > .pro-sidebar-inner {
  background-color: #142947 !important;
}

.pro-inner-list-item {
  background-color: #10233e !important;
}

.popper-inner {
  background-color: #10233e !important;
}

.side-bar-height {
  height: calc(100vh - 91px) !important;
  z-index: 1030 !important;
}

/* .side-bar-menu-icon {
  float: left;
  margin: 10px;
} */

.pro-sidebar {
  width: 250px !important;
  min-width: 250px !important;
}

.pro-sidebar.collapsed {
  width: 50px !important;
  min-width: 50px !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  padding: 8px 5px 8px 7px !important;
}

.pro-item-content {
  font-size: 13px;
}
