/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.list-row {
  cursor: pointer;
}

.list-row:hover {
  background-color: lightcyan;
}

th {
  font-size: 12px;
  font-weight: bold;
  background-color: #191c1f;
  color: white;
  text-align: center !important;
}

td {
  font-size: 12px;
}

.dropdown {
  font-size: 12px;
}

.spinner {
  animation: spin infinite 2s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  overflow: hidden;
}

.background-image {
  background-image: url('../public/david-marcu-VfUN94cUy4o-unsplash.jpg');
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  background-color: #ffffff;
  margin: 0;
  padding: 0;
}