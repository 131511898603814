.error-tracker table {
  display: flex;
  flex-flow: column;
  width: 100%;
}

thead {
  flex: 0 0 auto;
}

.error-tracker tbody {
  flex: 1 1 auto;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
}

.error-tracker tr {
  width: 100%;
  display: table;
  table-layout: fixed;
}

.error-tracker .error-tracker-row {
  text-align: left;
}

.error-tracker .error-tracker-search-field {
  margin-top: 7px;
  margin-bottom: 10px;
  width: 200px;
}

.error-tracker .error-tracker-warning-message {
  color: red;
  font-style: italic;
}

.error-tracker .error-tracker-selected-row {
  background-color: lightcyan;
}

.react-json-view {
  word-break: break-all;
}