.undertext {
  font-size: 12px;
}

.qr-text {
  color: #00008b;
  font-size: 15px;
}

@media (max-width: 800px) {
  .top-margin {
    margin-top: 30%;
  }
}

@media (min-width: 800px) {
  .top-margin {
    margin-top: 250px;
    min-height: 100vh;
  }
}