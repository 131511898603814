.view-table .filter-box {
  font-size: 12px;
}

.selectable-row {
  cursor: pointer;
}

.selectable-row:hover {
  background-color: lightcyan;
}

.date-picker-font {
  font-size: 13px;
}

.dark-orange {
  color: darkorange;
}
