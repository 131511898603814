.price-tracker .category-blue {
  color: #00008b;
}

.price-tracker .edit-icon {
  color: black;
  cursor: pointer;
  margin-left: 10px;
  margin-bottom: 5px;
}

.price-tracker .edit-icon:hover {
  color: rgb(105, 103, 103);
}

.price-tracker .pointer {
  cursor: pointer;
}

.vendor-detail .category-titles {
  font-size: 12px;
  font-weight: bold;
  padding-right: 20px;
}

.vendor-detail .category-text {
  color: #00008b;
  min-width: 135px;
}

.vendor-detail .section-header {
  font-size: 17px;
  font-style: italic;
  font-weight: bold;
}

.price-tracker .dark-orange {
  color: darkorange;
}

.vendor-detail .active-listing-download:hover {
  color: black
}