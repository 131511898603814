.order-detail .category-titles {
  font-size: 12px;
  font-weight: bold;
  padding-right: 20px;
}

.order-detail .category-text {
  color: #00008b;
  min-width: 135px;
}

.order-detail .category-blue {
  color: #00008b;
}

.order-detail .carrier {
  color: #006400;
  font-size: 12px;
  font-weight: bold;
  padding-right: 20px;
}

.order-detail .error-text {
  color: red;
  font-weight: bold;
  float: right;
  text-align: right;
}

.order-detail .distributor-header {
  color: #006400;
  font-style: italic;
  font-weight: bold;
  margin-bottom: 5px;
}

.order-detail hr {
  border: 0;
  clear:both;
  display:block;
  width: 96%;               
  background-color:lightgray;
  height: 1px;
}

.order-detail .edit-icon {
  color: black;
  cursor: pointer;
  margin-left: 10px;
  margin-bottom: 5px;
}

.order-detail .edit-icon:hover {
  color: rgb(105, 103, 103);
}

.order-detail .pointer {
  cursor: pointer;
}

.order-detail .section-header {
  font-size: 17px;
  font-style: italic;
  font-weight: bold;
}