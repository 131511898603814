.backstock .input-label {
  font-size: 14px;
}

.backstock .not-found {
  color: blue;
  font-size: 14px;
  font-weight: bold;
  font-style: italic;
}

.backstock .not-found {
  color: blue;
  font-size: 14px;
  font-weight: bold;
  font-style: italic;
}

.backstock .box {
  display: flex;
  justify-content: center;
  text-align: center;
}

.backstock .category-titles {
  font-size: 12px;
  font-weight: bold;
  padding-right: 20px;
}

.backstock .category-text {
  color: #00008b;
  min-width: 135px;
}

.backstock .category-blue {
  color: #00008b;
}

.backstock .image {
  cursor: pointer;
  height: auto;
  max-height: 180px;
  width: 70%;
}

.backstock .section-header {
  font-size: 17px;
  font-style: italic;
  font-weight: bold;
}

.backstock .info-text {
  font-size: 13px;
  font-style: italic;
}

.backstock .label-print {
  cursor: pointer;
  font-size: 20px;
}

.backstock .label-print:hover {
  color: rgb(85, 84, 84);
}

.backstock .label-case-number {
  font-size: 40px;
  font-weight: bold;
}

.backstock .label-identifier {
  font-size: 65px;
  font-weight: bold;
  border: 1px solid black;
  display: inline-block; /* Ensure the border wraps only around the text */
  padding: 5px 15px 5px 15px; /* Add some padding for better visual appearance */
}

.backstock .shipping-label-printed-message {
  font-size: 15px;
  font-weight: bold;
  color: red;
}
